const BASE_URL_AUTH = `${process.env.VUE_APP_AUTH_SERVICE_URL}/v1/cms`;
const BASE_URL_MASTER = `${process.env.VUE_APP_MASTER_SERVICE_URL}/v1/cms`;
const BASE_URL_TRC = `${process.env.VUE_APP_TRC_SERVICE_URL}/v1/cms`;

// Auth
export const API_AUTH = {
  LOGIN: `${BASE_URL_AUTH}/authorization/login`,
  LOGOUT: `${BASE_URL_AUTH}/authorization/logout`,
  FORGOT_PASSWORD: `${BASE_URL_AUTH}/authorization/forgot-password`,
  CHANGE_PASSWORD: `${BASE_URL_AUTH}/authorization/change-password`,
  PROFILE: `${BASE_URL_AUTH}/account/profile`,
  ROLE_MENU: `${BASE_URL_AUTH}/account/role-menu`,
  MENU: `${BASE_URL_AUTH}/master/menu`,
  CREATE_MENU: `${BASE_URL_AUTH}/master/menu`,
  UPDATE_MENU: `${BASE_URL_AUTH}/master/menu`,
  DETAIL_MENU: id => `${BASE_URL_AUTH}/master/menu/${id}`,
  PARENT: `${BASE_URL_AUTH}/master/menu/parent`,
  USER: `${BASE_URL_AUTH}/master/user`,
  CREATE_USER: `${BASE_URL_AUTH}/master/user`,
  UPDATE_USER: (id) =>`${BASE_URL_AUTH}/master/user/${id}`,
  DETAIL_USER: id => `${BASE_URL_AUTH}/master/user/${id}`,
  DEPARTMENT: `${BASE_URL_AUTH}/master/user/departments`,
  REGION: `${BASE_URL_AUTH}/master/user/regions`,
  SUB_REGION: `${BASE_URL_AUTH}/master/user/sub-regions`,
  BRANCHES: `${BASE_URL_AUTH}/master/user/branches`,
  ROLES: `${BASE_URL_AUTH}/master/user/roles`,
  ROLE: `${BASE_URL_AUTH}/master/role`,
  CREATE_ROLE: `${BASE_URL_AUTH}/master/role`,
  UPDATE_ROLE: (id)=>`${BASE_URL_AUTH}/master/role/${id}`,
  DETAIL_ROLE: id => `${BASE_URL_AUTH}/master/role/${id}`,
  MODULE: `${BASE_URL_AUTH}/master/module`,
  VENDOR: `${BASE_URL_AUTH}/master/vendors`,
  CREATE_VENDOR: `${BASE_URL_AUTH}/master/vendors`,
  UPDATE_VENDOR: `${BASE_URL_AUTH}/master/vendors`,
  DETAIL_VENDOR: id => `${BASE_URL_AUTH}/master/vendors/${id}`,
  UPLOAD_AGREEMENT: `${BASE_URL_AUTH}/master/vendors/upload-agreement`,
  GET_STATUS_VENDOR: `${BASE_URL_AUTH}/master/vendors/status`,
};

export const API_MASTER = {
  MENU: `${BASE_URL_MASTER}/master/menu`,
  CREATE_MENU: `${BASE_URL_MASTER}/master/menu`,
  UPDATE_MENU: `${BASE_URL_MASTER}/master/menu`,
  DETAIL_MENU: id => `${BASE_URL_MASTER}/master/menu/${id}`,
  PARENT: `${BASE_URL_MASTER}/master/menu/parent`,
  USER: `${BASE_URL_MASTER}/master/user`,
  CREATE_USER: `${BASE_URL_MASTER}/master/user`,
  UPDATE_USER: (id) =>`${BASE_URL_MASTER}/master/user/${id}`,
  DETAIL_USER: id => `${BASE_URL_MASTER}/master/user/${id}`,
  DEPARTMENT: `${BASE_URL_MASTER}/master/user/departments`,
  REGION: `${BASE_URL_MASTER}/master/user/regions`,
  SUB_REGION: `${BASE_URL_MASTER}/master/user/sub-regions`,
  BRANCHES: `${BASE_URL_MASTER}/master/user/branches`,
  ROLES: `${BASE_URL_MASTER}/master/user/roles`,
  ROLE: `${BASE_URL_MASTER}/master/role`,
  CREATE_ROLE: `${BASE_URL_MASTER}/master/role`,
  UPDATE_ROLE: (id)=>`${BASE_URL_MASTER}/master/role/${id}`,
  DETAIL_ROLE: id => `${BASE_URL_MASTER}/master/role/${id}`,
  MODULE: `${BASE_URL_MASTER}/master/module`,
  MAPPING_APPROVAL: `${BASE_URL_MASTER}/master/approval`,
  CREATE_MAPPING_APPROVAL: `${BASE_URL_MASTER}/master/approval`,
  UPDATE_MAPPING_APPROVAL: `${BASE_URL_MASTER}/master/approval`,
  DETAIL_MAPPING_APPROVAL: id => `${BASE_URL_MASTER}/master/approval/${id}`,
  TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit`,
  CREATE_TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit`,
  UPDATE_TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit`,
  DETAIL_TARGET_VISIT: id => `${BASE_URL_MASTER}/master/target-visit/${id}`,
  UPLOAD_TARGET_VISIT: `${BASE_URL_MASTER}/master/target-visit/upload`,
  BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet`,
  CREATE_BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet`,
  UPDATE_BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet`,
  DETAIL_BR_OUTLET: id => `${BASE_URL_MASTER}/master/br-outlet/${id}`,
  UPLOAD_BR_OUTLET: `${BASE_URL_MASTER}/master/br-outlet/upload`,
  BUDGET: `${BASE_URL_MASTER}/master/target-visit`,
  CREATE_BUDGET: `${BASE_URL_MASTER}/master/target-visit`,
  UPDATE_BUDGET: `${BASE_URL_MASTER}/master/target-visit`,
  DETAIL_BUDGET: id => `${BASE_URL_MASTER}/master/target-visit/${id}`,
  UPLOAD_BUDGET: `${BASE_URL_MASTER}/master/target-visit/upload`,
  PROVINCE: `${BASE_URL_MASTER}/master/regions/provinsi`,
  CITY: `${BASE_URL_MASTER}/master/regions/kabupaten`,
  DISTRICT: `${BASE_URL_MASTER}/master/regions/kecamatan`,
  VILLAGE: `${BASE_URL_MASTER}/master/regions/kelurahan`,
  BANK: `${BASE_URL_MASTER}/master/banks`,
  BRANCHES_BY_USER: `${BASE_URL_AUTH}/master/user/branches`,
  TAX: `${BASE_URL_MASTER}/master/tax-code`,

  EVENT: `${BASE_URL_TRC}/event`,
  DETAIL_EVENT: id => `${BASE_URL_TRC}/event/${id}`,

  ITEM: `${BASE_URL_MASTER}/master/detail-item`,

  PO_EVENT: `${BASE_URL_TRC}/po/event`,
  CREATE_PO_EVENT: `${BASE_URL_TRC}/po/event`,
  UPDATE_PO_EVENT: `${BASE_URL_TRC}/po/event`,
  DETAIL_PO_EVENT: `${BASE_URL_TRC}/po/event/by-po-number`,

  INVOICE_EVENT: `${BASE_URL_TRC}/invoice`,
  CREATE_INVOICE_EVENT: `${BASE_URL_TRC}/invoice`,
  UPDATE_INVOICE_EVENT: `${BASE_URL_TRC}/invoice`,
  DETAIL_INVOICE_EVENT: id => `${BASE_URL_TRC}/invoice/${id}`,

  APPROVAL_INVOICE: `${BASE_URL_TRC}/approval/invoice`,
  CREATE_APPROVAL_INVOICE: `${BASE_URL_TRC}/approval/invoice`,

  APPROVAL_PO: `${BASE_URL_TRC}/approval/po/event`,
  CREATE_APPROVAL_PO: `${BASE_URL_TRC}/approval/po/event`,

  APPROVAL_VENDOR: `${BASE_URL_AUTH}/master/approval/vendor`,
  CREATE_APPROVAL_VENDOR: `${BASE_URL_AUTH}/master/approval/vendor`,

  INVOICE_BY_NUMBER: `${BASE_URL_TRC}/invoice/by-invoice-number`,
};