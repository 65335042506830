import { createStore } from 'vuex';
import auth from './modules/auth';
import account from './modules/account';
import user from './modules/user';
import menu from './modules/menu';
import role from './modules/role';
import mappingApproval from './modules/mapping-approval';
import outlet from './modules/outlet';
import targetVisit from './modules/target-visit';
import vendor from './modules/vendor';
import budget from './modules/budget';
import poEvent from './modules/po-event';
import invoiceEvent from './modules/invoice-event';
import approvalPo from './modules/approval-po';
import approvalInvoice from './modules/approval-invoice';
import approvalVendor from './modules/approval-vendor';

export default createStore({
  modules: {
    auth,
    account,
    user,
    menu,
    role,
    mappingApproval,
    outlet,
    targetVisit,
    vendor,
    budget,
    poEvent,
    invoiceEvent,
    approvalPo,
    approvalInvoice,
    approvalVendor
  }
});
