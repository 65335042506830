import { apiGetAuth, apiPostAuthWithImage, apiPutAuth, apiPostAuth } from '@/utils/api'; 
import { API_MASTER } from '@/utils/api-url'; 

const state = { 
  approval_invoices: [], 
  approval_invoice: null, 
  total: 0, 
  loading: false, 
  error: null 
}; 

const mutations = { 
  SET_APPROVAL_INVOICES(state, approval_invoices) { 
    state.approval_invoices = approval_invoices; 
  }, 
  SET_APPROVAL_INVOICE(state, approval_invoice) { 
    state.approval_invoice = approval_invoice; 
  }, 
  SET_TOTAL(state, total) { 
    state.total = total; 
  }, 
  SET_LOADING(state, isLoading) { 
    state.loading = isLoading; 
  }, 
  SET_ERROR(state, error) { 
    state.error = error; 
  } 
}; 

const actions = { 
  async getApprovalInvoice({ commit }, { limit, page, search }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.APPROVAL_INVOICE}?limit=${limit}&page=${page}&search=${search}`); 
      if (response.data.status_code === 200) { 
        
        commit('SET_APPROVAL_INVOICES', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async createApprovalInvoice({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPostAuthWithImage(API_MASTER.CREATE_APPROVAL_INVOICE, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async updateApprovalInvoice({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPutAuth(API_MASTER.UPDATE_APPROVAL_INVOICE(payload.id), payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async getApprovalInvoiceByNumber({ commit }, id) { 
    console.log(id);
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.INVOICE_BY_NUMBER}?invoice_number=${id}`); 
      if (response.data.status_code === 200) { 
        return response.data.data; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getModule({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.MODULE}`); 
      if (response.data.status_code === 200) {
        // Return the mapped module data
        return response.data.data.map(module => ({
          id: module.id,
          name: module.module_name // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getParent({ commit }, { module_id }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.PARENT}?module_id=${module_id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data.map(parent => ({
          id: parent.id,
          name: parent.module_name // Adjust to use module_name for parents
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getEventById({ commit }, id ) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.EVENT}/${id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getItems({ commit }) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.ITEM}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        return response.data.data.data.map(item => ({
          id: item.item_code,
          value: item.item_code,
          name: item.item_name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getInvoiceEventById({ commit }, id) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.INVOICE_EVENT}/${id}`); 
      if (response.data.status_code === 200) { 
        commit('SET_INVOICE_EVENT', response.data.data); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async approveInvoice({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPostAuth(API_MASTER.APPROVAL_INVOICE, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

}; 

const getters = { 
  getApprovalInvoices(state) { 
    return state.approval_invoices; 
  }, 
  getApprovalInvoice(state) { 
    return state.approval_invoice; 
  }, 
  getTotalApprovalInvoices(state) { 
    return state.total; 
  }, 
  isLoading(state) { 
    return state.loading; 
  }, 
  getError(state) { 
    return state.error; 
  } 
}; 

export default { 
  namespaced: true, 
  state, 
  mutations, 
  actions, 
  getters 
};
